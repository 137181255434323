import { AccountModalMenus } from "src/components/topbar/components/AccountModalMenus";
import { ACCOUNT_MENUS } from "src/components/topbar/components/menus";
import { useUser } from "./useUser";
import { useTranslation } from "react-i18next";
import { Table, TableBody, TableCell, TableRow } from "src/components/ui/table";
import { useEffect, useState } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "src/components/ui/avatar"
import { useLocation, useNavigate } from "react-router-dom";
import CustomCheckbox from "src/components/CustomCheckbox";

const useTopbarMenu = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user, logout } = useUser();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const filterMenu = (group) => { return ACCOUNT_MENUS.filter(menu => menu.path.includes(group)) };
  const handleBack = () => navigate(-1);
  const handleToMyAccount = () => navigate('/my-account');
  const checkIfHasForms = location.pathname === "/my-account" || location.pathname === "/billing/billing-address";

  const DefaultComp = () => {
    const navToPath = (val) => navigate(val.path);
    return (
      <section className="flex flex-col gap-10">
        <div className="bg-lightgray-100 rounded-lg px-4 py-3 flex items-center gap-3 cursor-pointer" onClick={handleToMyAccount}>
          <Avatar className='w-13 h-13'>
            <AvatarImage src=""/>
            <AvatarFallback>{user?.first_name[0]}</AvatarFallback>
          </Avatar>
          <div className="leading-5">
            <h3 className="text-black text-base">{`${user?.first_name} ${user?.last_name}` || "Name Surname"}</h3>
            <p className="text-lightgray-900/60 text-sm">{user?.email || 'test@mail.com'}</p>
          </div>
        </div>
        <AccountModalMenus title="BILLING & PLAN" menus={filterMenu("billing")} onSelect={navToPath} className="pl-4" />
        <AccountModalMenus title="TEAMS MEMBER" menus={filterMenu("teams")} onSelect={navToPath} className="pl-4" />
        <AccountModalMenus title="LANGUAGE" className="pl-4">
          <Table className="relative left-10">
            <TableBody>
              <LanguageChoice language="th" type="Thai" />
              <LanguageChoice language="en" type="English" />
            </TableBody>
          </Table>
        </AccountModalMenus>
        <AccountModalMenus menus={filterMenu("logout")} className="pl-4" onSelect={logout} />
      </section>
    )
  }

  useEffect(() => {
    if (localStorage.lang === undefined || null) {
      localStorage.setItem('lang', i18n.language)
    }
  }, [localStorage.lang])

  const LanguageChoice = ({ language, type }) => {
    const changeLang = (lang) => {
      if (localStorage.lang !== null) {
        i18n.changeLanguage(lang)
        localStorage.setItem('lang', lang)
      }
    }
    const handleChangeLang = () => changeLang(language);
    return (
      <TableRow className="cursor-pointer">
        <TableCell className="px-0 py-3 -ml-10 flex items-center w-full font-normal text-black text-base gap-4" onClick={handleChangeLang}>
          <CustomCheckbox isChecked={localStorage.lang === language}/>
          <p>{type}</p>
        </TableCell>
      </TableRow>
    )
  }
  const defaultMenu = {
    title: "Account",
    element: <DefaultComp />,
    path: "/account",
  }

  return { defaultMenu, isModalOpen, setIsModalOpen, handleBack, checkIfHasForms }
}

export default useTopbarMenu