import { Check } from "lucide-react"

const CustomCheckbox = ({ isChecked, ...props }) => {
  return (
    <>
      <input type="checkbox" className="hidden" checked={isChecked} {...props}/>
      {isChecked ? (
        <span className="flex min-w-[24px] w-6 h-6 bg-link rounded-full items-center justify-center">
          <Check color="white" className="w-4 h-4" viewBox="0 0 24 24" />
        </span>
      ) : (
        <span className="flex min-w-[24px] w-6 h-6 rounded-full border-2 border-lightgray-950"></span>
       )}
    </>
  )
}

export default CustomCheckbox