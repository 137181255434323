import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { Avatar, AvatarFallback, AvatarImage } from "../../components/ui/avatar"
import { useState } from 'react'
import { useUser } from "src/hooks/useUser";
import { useTranslation } from "react-i18next";
import { AccountModalButton, AccountModalMenus } from "src/components/topbar/components/AccountModalMenus";
import { Icons } from "src/components/ui/icons";
import { SearchMd } from "@untitled-ui/icons-react/build/cjs";
import { textGradient } from "src/lib/utils";
import TeamMemberIcon from "src/components/icon-menus/TeamMemberIcon";
import { Popover, PopoverContent, PopoverTrigger } from "src/components/ui/popover";
import { Command, CommandGroup, CommandItem, CommandList } from "src/components/ui/command";
import { Dialog, DialogContent, DialogTitle, DialogDescription, DialogFooter, DialogClose, DialogHeader, DialogOverlay } from "src/components/ui/dialog";
import { useMutation } from "react-query";
import { team } from "src/client/api";
import { Loader2 } from "lucide-react";

export default function TeamMembers() {
  const { auth, getUser } = useUser();
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const [removeMember, setRemoveMember] = useState(false)
  const allMembers = auth?.team_members.filter(name => name.first_name.toUpperCase().includes(search.toUpperCase()) || name.last_name.toUpperCase().includes(search.toUpperCase()))
  const { mutate, isLoading } = useMutation(() => team.remove_team_member({ user_email: removeMember }), {
    onSuccess: () => getUser().then(() => setRemoveMember(null)),
  })

  return (
    <>
      {auth?.team_members?.length > 0 ? (
        <div className="flex flex-col items-center gap-10">
          <Icons.teamPageIcon />
          <div className='w-full max-w-[358px] shadow-team-members bg-white h-11 flex rounded-lg items-center px-3'>
            <SearchMd className="h-5 w-5 text-lightgray-900/60" />
            <Input className="bg-white !outline-none !ring-0 !ring-offset-0 !text-base placeholder:text-center" placeholder={t("Search or enter member name")} value={search} onChange={(e) => setSearch(e.target.value)} />
          </div>
          <section className="flex flex-col gap-3 w-full relative">
            {allMembers?.length > 0 ? allMembers?.map((member, index) => (
              <Popover portal modal={true}>
                <PopoverTrigger className="w-full">
                  <AccountModalMenus>
                    <AccountModalButton
                      className="p-4"
                      title={`${member.first_name} ${member.last_name}`}
                      desc={member.roles[0]}
                      icon={(
                        <Avatar>
                          <AvatarImage src={member.avatar} />
                          <AvatarFallback>{member.first_name[0]}{member.last_name[0]}</AvatarFallback>
                        </Avatar>
                      )}
                    />
                  </AccountModalMenus>
                </PopoverTrigger>
                {allMembers?.length > 1 ? <PopoverContent className={`p-0 ${removeMember ? "hidden" : "!z-99"}`} align="end">
                  <Command>
                    <CommandList>
                      <CommandGroup className="p-1.5">
                        {/* <CommandItem className="flex items-start gap-x-4">
                          Admin
                        </CommandItem>
                        <CommandItem className="flex items-start gap-x-4">
                          Member
                        </CommandItem> */}
                        <CommandItem onSelect={() => setRemoveMember(member.name)}>
                          Remove
                        </CommandItem>
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent> : null}
              </Popover>
            )) : <p className="text-center">{t("Team members not found")}</p>}
            <Dialog open={removeMember} onOpenChange={setRemoveMember}>
              <DialogOverlay className="!z-[100]" />
              <DialogContent className="!z-[100] w-[90%]">
                <DialogHeader>
                  <DialogTitle>{t('teams.remove_team.title')}</DialogTitle>
                  <DialogDescription>
                    {t('teams.remove_team.desc')}
                  </DialogDescription>
                  <DialogFooter className="!mt-8">
                    <DialogClose>
                      <Button variant='outline'>{t('cancel')}</Button>
                    </DialogClose>
                    <Button
                      disabled={isLoading}
                      onClick={mutate}
                    >
                      {t('teams.remove_team.continue')}
                      {isLoading && <Loader2 size="16" className="ml-1 animate-spin" />}
                    </Button>
                  </DialogFooter>
                </DialogHeader>
              </DialogContent>
            </Dialog>
          </section>
        </div>
      ) : (
        <div className="w-[400px] flex flex-col items-center gap-y-10 text-center mx-auto justify-center">
          <TeamMemberIcon className="w-24 h-24" />
          <div className="flex flex-col gap-y-6 sf-pro-rounded px-5">
            <h1 className="text-4xl font-bold"><span className="inline-block" style={textGradient("linear-gradient(90deg, #6600E9 25.6%, #A600E0 72.12%)")}>WorkSpace</span> Sharing and team invite</h1>
            <p className="text-sm text-secondary-foreground">Share your workspace, start chat and meeting collaboration, manage stock and create multiple team and department for your team here.</p>
          </div>
          <Button className="regis-btn">Manage Team Members</Button>
        </div>
      )}
    </>
  )
}